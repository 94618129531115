.menu-general {
    width: 100%;
    background-color: #18325c;
}

.logo {
    width: 55%;
}

.flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.f-start-center-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.f-start-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flex-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
.ul-style-none {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
}


.title {
    margin-bottom: 5px;
    font-size: 47px;
}

.subtitle {
    font-size: 32px;
}

/* CSS */
.button-23 {
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  outline: none;
  padding: 18px 28px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-23:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

.button-23:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}
/* CSS */
.button-24 {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
  }
  
  .button-24:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .button-24:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .button-24:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }
  