.liens-footer-fb {
    text-decoration: none;
    color: white;
}

.liens-footer {
    font-size: 16px;
    text-decoration: none;
    color: grey;
}

