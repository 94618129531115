.s1-photos-dev {
    background: url("http://localhost:8000/media/media/site/optimize/bg.png") no-repeat;
    background-size: cover;
}

.s2-photos-dev {
    background: url('http://localhost:8000/media/media/site/optimize/2.jpg') no-repeat;
    background-size: cover;
}
.s3-photos-dev {
    background: url('http://localhost:8000/media/media/site/optimize/3.jpg') no-repeat;
    background-size: cover;
}
.s4-photos-dev {
    background: url('http://localhost:8000/media/media/site/optimize/4.jpg') no-repeat;
    background-size: cover;
}

.s1-photos {
    background: url("https://api.pierrotechnic.fr/media/media/site/optimize/bg.png") no-repeat;
    background-size: cover;
}

.s2-photos {
    background: url('https://api.pierrotechnic.fr/media/media/site/optimize/2.jpg') no-repeat;
    background-size: cover;
}
.s3-photos {
    background: url('https://api.pierrotechnic.fr/media/media/site/optimize/3.jpg') no-repeat;
    background-size: cover;
}
.s4-photos {
    background: url('https://api.pierrotechnic.fr/media/media/site/optimize/4.jpg') no-repeat;
    background-size: cover;
}

.arrow {
	width: 0;
	height: 40px;
	border: 1px solid #333;
	position: relative;
	animation: scroll 1.5s infinite;
	-webkit-animation: scroll 1.5s infinite;
}

.arrow::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -5px;
    width: 1px;
    height: 10px;		
    border-top: 10px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

.section-arrow {
    height: calc(30vh - 160px);
}

.section-2 {
    min-height: 50vh;
    padding-top: 20px;
}

.card-contains {
    min-height: 30vh;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.footer-row-section {
    background-color: #303030;
}

.decoratorLink a {
    color: white;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
}

.decoratorLink a:hover {
    color:  #a9c1e8;
    text-decoration: none;
}

body {
    overflow-x: hidden;
}
